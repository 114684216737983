import React from 'react';
import { IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import './styles.css';

// import video
import videoBg from '../src/assets/video.mp4';

const App = () => {
  return (
    <section className='page'>
      {/* overlay */}
      <div className='overlay'></div>
      <video src={videoBg} autoPlay loop muted />
      <div className='page__content'>
        <h1>Launching Soon</h1>
        <h3>
          Leave your email and we'll let you know once the site goes live.
        </h3>
        <FlipClockCountdown
          className='flip-clock'
          to={new Date('July 1, 2023').getTime() + 24 * 3600 * 1000 + 5000}
        />
        <button className='btn'  onClick={() => window.location = 'mailto:info@louderthanthelobby.com?subject=Add%20Me%20To%20List'}>Notify me</button>
        <br />
        <br />
        <div>
          <IconButton href="https://www.instagram.com/louderthanthelobby/" target="_blank" sx={{ color: "white", background: 'linear-gradient(to right bottom, #405DE6, #E1306C, #E1306C)'}}>
            <InstagramIcon />
          </IconButton>&nbsp;&nbsp;
          <IconButton href="https://twitter.com/louderthanlobby" target="_blank" sx={{ color: "white", backgroundColor: "#1DA1F2"}}>
            <TwitterIcon />
          </IconButton>
        </div>
      </div>
    </section>
  );
};

export default App;
